const lang = {
  add_user: "Add User",
  administrator: "Administrator",
  all: "All",
  branch: {
    all: "All",
    chiryu: "Chiryu",
    honsha: "Main Office",
    shimane: "Shimane",
    tokoname: "Tokoname",
  },
  modal: {
    submission_successful: "Your data has been submitted successfully.",
    registration_successful: "Registration Successful. Go to the login page",
  },
  months: {
    previous_month: "Previous Month",
    this_month: "This Month",
    next_month: "Next Month",
    next_next_month: "Next Next Month",
    current_month: "Current Month",
    next_next_next_month: "▶️▶️▶️ Month",
  },
  admin_navbar: {
    administrator: "Administrator",
    monthly_summary: "Monthly Summary",
    food_history: "Food History",
    employee: "Employee Information",
    dept_wise: "Department Wise",
    daily_counts: "Daily Count",
    checkin_status: "CheckIn Status",
    permission: "Permission",
    logout: "Logout",
    generatefood: "Generate Food",
    delivery_person_checkbox: "Today's Order Checkbox",
  },
  login_register_page: {
    login_header: "Please Login in to your account",
    register_header: "Register your account",
    your_email: "Your EasyproId",
    password: "Password",
    easyproid: "EasyproId",
    new_user: "New User ??",
    already_have_an_account: "Do you already have an account ??",
    signup: "Register",
    signin: "Login",
  },
  food_selection_page: {
    deadline: "Changes for today must be made by 8:45! ! !",
    link_text:
      "Summer limited noodle menu available♪ Click here for order dates",
    food_selection_page: "Food Selection Page",
  },
  cancel: "Cancel",
  do_you_already_have_an_account: "Do you already have an account ??",
  easyproid: "Easypro ID",
  employee_information: "Employee Information",
  employee_profile: {
    easyproid: "Easypro ID",
    employee_profile: "Employee Profile",
    employment_status: "Employee Status",
    group: "Group",
    name: "Name",
    team: "Team",
    work_location: "Work Location",
  },
  employee_information_page: {
    employee_information: "Employee Information",
    add_user: "Add User",
    remove_user: "Remove User",
    update_user: "Update User",
  },
  page_titles: {
    login_page: "Login - お弁当注文",
    register_page: "Register - お弁当注文",
    food_selection_page: "Food Selection - お弁当注文",
    profile_page: "Profile - お弁当注文",
    homepage: "お弁当注文",
  },
  food_generation_page: {
    food_generation_page: "Data Generation Page",
    selected_month: "Selected Month",
    generate_food: "Generate Data",
    data_submission_thank: "Data has been submitted. Thank You.",
  },
  everyone: "Everyone",
  export_to_excel: "Export to Excel",
  first_time: "New User ??",
  food_history: "Food History",
  homepage: "HomePage",
  select_order_location: "Select Order Location",
  login: "Login",
  login_page: "Login Page",
  login_header: "Sign in to your account",
  logout: "Logout",
  monthly_summary: "Monthly Summary",
  name: "Name",
  next: "Next Page",
  next_month: "Next Month",
  order_history: "Order History",
  orders: "Orders",
  previous_month: "Previous Month",
  profile: "Employee Profile",
  register: "Register",
  remove_food: "Remove Food",
  remove_user: "Remove User",
  shimane: "Shimane",
  submit: "Submit",
  team: "Team",
  this_month: "This Month",
  tokoname: "Tokoname",
  update: "Update",
  update_location: "Update Location",
  user_registration: "User Registration",
  work_location: "Work Location",

  day: {
    1: "Sun",
    2: "Mon",
    3: "Tue",
    4: "Wec",
    5: "Thu",
    6: "Fri",
    7: "Sat",
  },
  password: "password",
  this_month_data: "This Month Data",
  last_month_data: "Previous Month Data",
  floor: "F",
  errors: {
    user_doesnot_exist: "User doesnot exists.",
    invalid_credentials: "Invalid Credentials",
    user_not_valid: "User is not valid.",
    user_already_exists: "The user already exists.",
    error_while_creating_user: "Something went wrong while creating the user",
    all_fields_required: "All fields need to be filled",
    id_mustbe_number: "User Id must be a number",
    no_token: "No token provided.",
    invalid_token: "Invalid Token",
    failed_to_authenticate_token: "Failed to authenticate token",
    authorization_fail: "Failed to authenticate the user",
  },
};

export default lang;
