const lang = {
  add_user: "Thêm người dùng",
  administrator: "Quản trị viên",
  all: "Tất cả",
  branch: {
    all: "Tất cả",
    chiryu: "Chiryu",
    honsha: "Trụ sở chính",
    shimane: "Shimane",
    tokoname: "Tokoname",
  },
  modal: {
    submission_successful: "Dữ liệu của bạn đã được gửi thành công.",
    registration_successful: "Đăng ký thành công. Vui lòng đến trang đăng nhập",
  },
  months: {
    previous_month: "Tháng trước",
    this_month: "Tháng này",
    next_month: "Tháng tới",
    next_next_month: "Tháng sau nữa",
    current_month: "Tháng hiện tại",
    next_next_next_month: "▶️▶️▶️ Tháng",
  },
  admin_navbar: {
    administrator: "Quản trị viên",
    monthly_summary: "Tóm tắt hàng tháng",
    food_history: "Lịch sử thực phẩm",
    employee: "Thông tin nhân viên",
    dept_wise: "Theo phòng ban",
    daily_counts: "Số lượng hàng ngày",
    checkin_status: "Trạng thái điểm danh",
    permission: "Quyền",
    logout: "Đăng xuất",
    generatefood: "Tạo thực phẩm",
    delivery_person_checkbox: "Hộp kiểm đơn hàng hôm nay",
  },
  login_register_page: {
    login_header: "Vui lòng đăng nhập vào tài khoản của bạn",
    register_header: "Đăng ký tài khoản của bạn",
    your_email: "Email của bạn",
    password: "Mật khẩu",
    easyproid: "Easypro ID",
    new_user: "Người dùng mới?",
    already_have_an_account: "Bạn đã có tài khoản chưa?",
    signup: "Đăng ký",
    signin: "Đăng nhập",
  },
  food_selection_page: {
    deadline: "Thay đổi cho hôm nay phải được thực hiện trước 8:45!",
    link_text: "Thực đơn mì mùa hè có sẵn♪ Nhấp vào đây để biết ngày đặt hàng",
    food_selection_page: "Trang chọn thực phẩm",
  },
  cancel: "Hủy",
  do_you_already_have_an_account: "Bạn đã có tài khoản chưa?",
  easyproid: "Easypro ID",
  employee_information: "Thông tin nhân viên",
  employee_profile: {
    easyproid: "Easypro ID",
    employee_profile: "Hồ sơ nhân viên",
    employment_status: "Trạng thái việc làm",
    group: "Nhóm",
    name: "Tên",
    team: "Đội",
    work_location: "Nơi làm việc",
  },
  employee_information_page: {
    employee_information: "Thông tin nhân viên",
    add_user: "Thêm người dùng",
    remove_user: "Xóa người dùng",
    update_user: "Cập nhật người dùng",
  },
  page_titles: {
    login_page: "Đăng nhập - Đặt cơm hộp",
    register_page: "Đăng ký - Đặt cơm hộp",
    food_selection_page: "Chọn thực phẩm - Đặt cơm hộp",
    profile_page: "Hồ sơ - Đặt cơm hộp",
    homepage: "Đặt cơm hộp",
  },
  food_generation_page: {
    food_generation_page: "Trang tạo dữ liệu",
    selected_month: "Tháng đã chọn",
    generate_food: "Tạo dữ liệu",
    data_submission_thank: "Dữ liệu đã được gửi. Cảm ơn bạn.",
  },
  everyone: "Mọi người",
  export_to_excel: "Xuất ra Excel",
  first_time: "Người dùng mới?",
  food_history: "Lịch sử thực phẩm",
  homepage: "Trang chính",
  select_order_location: "Chọn địa điểm đặt hàng",
  login: "Đăng nhập",
  login_page: "Trang đăng nhập",
  login_header: "Đăng nhập vào tài khoản của bạn",
  logout: "Đăng xuất",
  monthly_summary: "Tóm tắt hàng tháng",
  name: "Tên",
  next: "Trang tiếp theo",
  next_month: "Tháng tới",
  order_history: "Lịch sử đặt hàng",
  orders: "Đơn hàng",
  previous_month: "Tháng trước",
  profile: "Hồ sơ nhân viên",
  register: "Đăng ký",
  remove_food: "Xóa thực phẩm",
  remove_user: "Xóa người dùng",
  shimane: "Shimane",
  submit: "Gửi",
  team: "Đội",
  this_month: "Tháng này",
  tokoname: "Tokoname",
  update: "Cập nhật",
  update_location: "Cập nhật địa điểm",
  user_registration: "Đăng ký người dùng",
  work_location: "Nơi làm việc",

  day: {
    1: "CN",
    2: "T2",
    3: "T3",
    4: "T4",
    5: "T5",
    6: "T6",
    7: "T7",
  },
  password: "Mật khẩu",
  this_month_data: "Dữ liệu tháng này",
  last_month_data: "Dữ liệu tháng trước",
  floor: "Tầng",
  errors: {
    user_doesnot_exist: "Người dùng không tồn tại.",
    invalid_credentials: "Thông tin đăng nhập không hợp lệ",
    user_not_valid: "Người dùng không hợp lệ.",
    user_already_exists: "Người dùng đã tồn tại.",
    error_while_creating_user: "Đã xảy ra lỗi khi tạo người dùng",
    all_fields_required: "Tất cả các trường đều cần phải được điền",
    id_mustbe_number: "ID người dùng phải là số",
    no_token: "Không có mã thông báo được cung cấp.",
    invalid_token: "Mã thông báo không hợp lệ",
    failed_to_authenticate_token: "Không thể xác thực mã thông báo",
    authorization_fail: "Xác thực người dùng thất bại",
  },
};

export default lang;
