// LanguageSwitcher.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { switchLanguage } from "../../redux/TranslatorReducer/action";

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.translator.language);

  useEffect(() => {}, [language]);

  const handleLanguageChange = (event) => {
    dispatch(switchLanguage(event.target.value));
  };

  return (
    <div className="inline-block relative w-30">
      <select
        onChange={handleLanguageChange}
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline text-gray-700"
      >
        <option value="jp">日本語</option>
        <option value="en">English</option>
        <option value="vi">Tiếng Việt</option>
        <option value="cn">中国語</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M5.454 6.321L4 7.779 10 14l6-6.221-1.454-1.458L10 10.898 5.454 6.321z" />
        </svg>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
