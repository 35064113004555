import * as api from "../api/index";
import {
  FETCH_SINGLE_USER_INFO,
  DELETE_USER,
  INSERT_USER,
  FETCH_SINGLE_USER_FOOD,
  FETCH_ALL_FOODS,
  DELETE_FOOD_ITEM,
  DAILY_FOOD_COUNTS,
  FETCH_SINGLE_USER_INFO_BY_ADMIN,
  ALL_EMP_INFORMATION,
  MONTHLY_SUMMARY,
  START_LOADING,
  CHECKED_IN_DATA,
  INSERT_FOOD,
  DEPARTMENT_WISE_DATA,
  DATE_CHANGE,
  LOGOUT,
  FETCH_PERMISSION,
  UPDATE_PERMISSION,
  GENERATE_FOODS,
  END_LOADING,
  UPDATE_CHECKBOX_BY_ADMIN,
} from "../constants/actionTypes";

import { DispatchErrorMessage } from "./helper";

// User Actions

export const fetch_single_user_info =
  (easyproId, language, navigate) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });

      const response = await api.FetchSingleUserInfo(easyproId);
      if (response.data.errorMessage) {
        alert(response.data.errorMessage);
        dispatch({ type: LOGOUT });
      } else {
        const data = response.data.data;

        dispatch({ type: FETCH_SINGLE_USER_INFO, data: data });
      }
    } catch (error) {
      DispatchErrorMessage(dispatch, error.response.data, language);
      navigate("/login");
    }
  };

export const fetch_single_user_foods =
  (easyproId, language, monthOffset, navigate) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });

      const response = await api.FetchSingleUserFood(easyproId, monthOffset);
      if (response.data.errorMessage) {
        alert(response.data.errorMessage);
        dispatch({ type: LOGOUT });
      } else {
        const data = response.data.data;
        dispatch({ type: FETCH_SINGLE_USER_FOOD, data: data });
      }
    } catch (error) {
      DispatchErrorMessage(dispatch, error.response.data, language);
      navigate("/login");
    }
  };

export const insert_food =
  (submissionData, monthOffset) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      await api.InsertFood(submissionData, monthOffset);
      dispatch({ type: INSERT_FOOD });
    } catch (error) {
      console.log(error);
    }
  };

// Admin Actions

export const monthly_summary =
  ({ branch, monthOffset }) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const response = await api.MONTHLY_SUMMARY({ branch, monthOffset });

      const data = response.data;

      dispatch({ type: MONTHLY_SUMMARY, data: data });
    } catch (error) {
      console.log(error);
    }
  };

export const fetch_all_foods = (monthOffset) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await api.AllFoodHistory2(monthOffset);
    const data = response.data.data;
    dispatch({ type: FETCH_ALL_FOODS, data: data });
  } catch (error) {
    console.log(error);
  }
};

export const fetch_all_emp_information = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await api.allEmpInfo2();
    const data = response.data.data;

    dispatch({ type: ALL_EMP_INFORMATION, data: data });
  } catch (error) {
    console.log(error);
  }
};

export const delete_user = (easyproId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    await api.deleteUser2(easyproId);
    dispatch({ type: DELETE_USER, data: easyproId });
  } catch (error) {
    console.log(error);
  }
};

export const insert_user = (formData, language) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const response = await api.insertUser2(formData);

    if (response.data.errorMessage) {
      DispatchErrorMessage(dispatch, response.data, language);
    } else {
      const data = response.data.data;
      dispatch({ type: INSERT_USER, data: data });
    }
  } catch (error) {
    alert("Something went wrong.");
    console.log(error);
  }
};

export const update_user = (formData, language) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await api.updateUser(formData);
    if (response.data.errorMessage) {
      DispatchErrorMessage(dispatch, response.data, language);
    } else {
      const data = response.data.data;
      dispatch({ type: INSERT_USER, data: data });
    }
  } catch (error) {}
};

export const daily_food_counts =
  ({ branch, monthOffset }) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const response = await api.DailyData({ branch, monthOffset });

      const data = response.data;

      dispatch({ type: DAILY_FOOD_COUNTS, data: data });
    } catch (error) {
      console.log(error);
    }
  };

export const checkIn_data = (currentDate) => async (dispatch) => {
  try {
    if (!currentDate) {
      currentDate = getCurrentDateInJapan();
    }

    dispatch({ type: START_LOADING });
    const response = await api.CheckedInData({
      currentDate,
      monthOffset: currentDate,
    });
    const data = response.data;
    dispatch({ type: CHECKED_IN_DATA, data: data });
  } catch (error) {
    console.log(error);
  }
};

export const fetch_single_user_info_by_admin =
  ({ id, monthOffset }) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const response = await api.FetchSingleUserInfoByAdmin({
        id,
        monthOffset,
      });
      const data = response.data;

      dispatch({ type: FETCH_SINGLE_USER_INFO_BY_ADMIN, data: data });
    } catch (error) {
      console.log(error);
    }
  };

export const delete_food_item = (easyproId, date) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    await api.DeleteFoodItem({ easyproId, date, monthOffset: date });
    dispatch({
      type: DELETE_FOOD_ITEM,
      data: { easyproId, date },
    });
  } catch (error) {
    console.log(error);
  }
};

export const update_food =
  ({ formData, monthOffset }) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const response = await api.Update_Food_Item({ formData, monthOffset });
      const data = response.data.data;
      dispatch({ type: FETCH_ALL_FOODS, data: data });
    } catch (error) {
      console.log(error);
    }
  };

export const department_wise_data =
  ({ branch, monthOffset }) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const response = await api.DepartmentWiseData(branch, monthOffset);
      const data = response.data;
      dispatch({ type: DEPARTMENT_WISE_DATA, data: data });
    } catch (error) {
      console.log(error);
    }
  };

export const date_change = (date) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = date;
    dispatch({ type: DATE_CHANGE, data: data });
  } catch (error) {
    console.log(error);
  }
};

// Additional Actions

export const fetch_permission_data = (monthOffset) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await api.PermissionData(monthOffset);

    dispatch({ type: FETCH_PERMISSION, data: response.data.data });
  } catch (error) {}
};

export const update_permission_data =
  (_id, allow, monthOffset) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });

      await api.updatePermissions(_id, allow, monthOffset);

      dispatch({ type: UPDATE_PERMISSION });
    } catch (error) {
      console.log(error);
    }
  };

export const generate_food = (monthOffset) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    await api.generate_food({ monthOffset });

    dispatch({ type: GENERATE_FOODS });
  } catch (error) {
    console.log(error);
  }
};

// Final Additional

export const update_checkbox_by_admin =
  ({ _id, date, branch }) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      await api.checkbox_update_by_admin({
        _id,
        monthOffset: date,
      });
      dispatch({ type: UPDATE_CHECKBOX_BY_ADMIN, data: { _id, branch } });
    } catch (error) {
      console.log(error);
    }
  };

export const submit_delivery_staff_checkbox =
  ({ dataToSubmit, date }) =>
  async (dispatch) => {
    try {
      await api.delivery_staff_checkbox({
        data: dataToSubmit,
        monthOffset: date,
      });
    } catch (error) {
      console.log("Something went wrong.", error);
    }
  };

// Helper Functions

function getCurrentDateInJapan() {
  // Create a Date object for the current time
  let now = new Date();

  // Convert it to the local time in Japan
  let japanTime = new Date(
    now.toLocaleString("en-US", { timeZone: "Asia/Tokyo" })
  );

  // Format the date as yyyy-mm-dd
  let year = japanTime.getFullYear();
  let month = japanTime.getMonth() + 1;
  let day = japanTime.getDate();

  // Pad the month and day with leading zeros if necessary
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  // Return the formatted date
  return `${year}-${month}-${day}`;
}
