import React, { useEffect, useState, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  daily_food_counts,
  submit_delivery_staff_checkbox,
} from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";

import BranchSelection from "../../components/BranchSelection";
//* Months Addition
import { getMonthOffset } from "../../utils/monthOffsetUtils";
import moment from "moment";
import Modal from "../../components/Modal";
import { useLanguage } from "../../hooks/useLanguage";

const DeliveryStaffTable = React.lazy(() =>
  import("./components/DeliveryStaffTable")
);

function DeliveryStaffCheckbox() {
  const [currentBranch, setCurrentBranch] = useState("本社");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const language = useLanguage();

  const [firstChild, setFirstChild] = useState(null);
  const [secondChild, setSecondChild] = useState(null);

  const branches = ["本社", "常滑", "知立", "島根", "名古屋", "東京", "博多"];

  //* Months Addition
  const [monthOffset, setMonthOffset] = useState(getMonthOffset("this_month"));

  useEffect(() => {
    dispatch(daily_food_counts({ branch: currentBranch, monthOffset }));
  }, [dispatch, currentBranch, monthOffset]);

  const data = useSelector((state) => state.crud.data.dailyCount?.data1);
  const data2 = useSelector((state) => state.crud.data?.dailyCount?.data2);

  if (!data) {
    return <LoadingPage />;
  }
  const headers = data[0];

  const Honsha_firstHalf = ["大盛", "おかずのみ", "小計"];
  const Honsha_secondHalf = [
    "こころ大盛",
    "こころおかず",
    "なでしこ",
    "麺＆ご飯",
    "麺",
    "小計",
  ];

  const Tokoname_firstHalf = ["大盛", "おかずのみ", "小計"];
  const Tokoname_secondHalf = [
    "常滑",
    "常滑大盛",
    "すこやかおかず",
    "すこやか",
    "すこやか大盛",
    "こころ〇",
    "麺",
    "小計",
  ];

  const Shimane_firstHalf = [
    "健康小盛(島根)",
    "健康おかず",
    "洋風ランチ",
    "洋風小盛(島根)",
    "洋風おかず",
    "麺",
    "小計",
  ];

  const Shimane_secondHalf = [
    "nicoデラックス(島根)",
    "nicoデラックス大(島根)",
    "小計",
  ];

  const Nagoya_firstHalf = [
    "ミノヤ大盛(名古屋)",
    "ミノヤおかず(名古屋)",
    "麺",
    "小計",
  ];

  const Tokyo_firstHalf = ["小計"];
  const Hakata_firstHalf = ["小計"];

  let firstHalf,
    secondHalf,
    firstRowSpan,
    secondRowSpan,
    firstHeading,
    secondHeading,
    firstCell,
    secondCell,
    FT,
    ST;

  switch (currentBranch) {
    case "本社":
    case "知立":
      firstHalf = Honsha_firstHalf;
      secondHalf = Honsha_secondHalf;
      firstRowSpan = 4;
      secondRowSpan = 7;
      firstHeading = "たつえ";
      firstCell = "普通盛";
      secondHeading = "玉清";
      secondCell = "こころ";
      FT = [2, 5];
      ST = [3, 10];
      break;
    case "常滑":
      firstHalf = Tokoname_firstHalf;
      secondHalf = Tokoname_secondHalf;
      firstRowSpan = 4;
      secondRowSpan = 9;
      firstHeading = "たつえ";
      firstCell = "普通盛";
      secondHeading = "とこなめ";
      secondCell = "常滑おかず";
      FT = [2, 7];
      ST = [3, 12];
      break;
    case "島根":
      firstHalf = Shimane_firstHalf;
      secondHalf = Shimane_secondHalf;
      firstRowSpan = 8;
      secondRowSpan = 4;
      firstHeading = "アゴ弁";
      firstCell = "健康弁当";
      secondHeading = "niconico";
      secondCell = "nicoヘルシー(島根)";
      FT = [6, 2];
      ST = [7, 11];

      break;
    case "名古屋":
      firstHalf = Nagoya_firstHalf;
      secondHalf = null;
      firstRowSpan = 5;
      secondRowSpan = 0;
      firstHeading = "ミノヤ大盛";
      firstCell = "ミノヤ普通(名古屋)";
      secondHeading = "";
      secondCell = "";
      FT = [3];
      ST = [4];

      break;
    case "東京":
      firstHalf = Tokyo_firstHalf;
      secondHalf = null;
      firstRowSpan = 2;
      secondRowSpan = 0;
      firstHeading = "田中新川";
      firstCell = "田中新川(東京)";
      secondHeading = "";
      secondCell = "";
      FT = [0];
      ST = [1];

      break;
    case "博多":
      firstHalf = Hakata_firstHalf;
      secondHalf = null;
      firstRowSpan = 2;
      secondRowSpan = 0;
      firstHeading = "はたなか";
      firstCell = "はたなか日替わり";
      secondHeading = "";
      secondCell = "";
      FT = [0];
      ST = [1];

      break;
    default:
      firstHalf = Shimane_firstHalf;
      secondHalf = null;
      firstRowSpan = 7;
      secondRowSpan = 0;
      firstHeading = "島根";
      firstCell = "健康弁当";
      secondHeading = "";
      secondCell = "";
      FT = [2, 3];
      ST = [3, 8];
      break;
  }

  // Extract counts data from the rest of the array
  const countsData = data.slice(1);

  // Extract the totalSum data for each date
  const totalSums = countsData.map((item) => item.totalSum);

  // Extract counts data excluding the totalSum
  const countsWithoutTotalSum = countsData.map(({ totalSum, ...rest }) => rest);

  // Add the totalSum as the last row for each date
  const updatedCountsData = countsWithoutTotalSum.map((item, index) => ({
    ...item,
    totalSum: totalSums[index],
  }));

  let updatedCountsData2;

  if (currentBranch === "常滑" && data2) {
    // Extract counts data from the rest of the array
    const countsData2 = data2.slice(1);

    // Extract the totalSum data for each date
    const totalSums = countsData2.map((item) => item.totalSum);

    // Extract counts data excluding the totalSum
    const countsWithoutTotalSum2 = countsData2.map(
      ({ totalSum, ...rest }) => rest
    );

    // Add the totalSum as the last row for each date
    updatedCountsData2 = countsWithoutTotalSum2.map((item, index) => ({
      ...item,
      totalSum: totalSums[index],
    }));
  }

  const handleSubmit = (floor) => {
    const date = moment().format("YYYY-MM-DD");
    let dataToTransmit;

    if (floor === 1) {
      if (!firstChild) return; // No data to submit for the first floor
      dataToTransmit = {
        branch: currentBranch,
        data: firstChild,
        floor: 1,
      };
    } else if (floor === 2) {
      if (!secondChild) return; // No data to submit for the second floor
      dataToTransmit = {
        branch: currentBranch,
        data: secondChild,
        floor: 2,
      };
    }

    if (dataToTransmit) {
      dispatch(
        submit_delivery_staff_checkbox({ dataToSubmit: dataToTransmit, date })
      );
      setShowModal(true);
    }
  };

  return (
    <div className="w-screen h-auto min-h-screen bg-gradient-to-r from-blue-200 to-cyan-200 flex flex-col">
      <div className="w-full h-ful flex-growl flex flex-col my-4 pt-12">
        <div className="w-full h-1/5 flex flex-row">
          <div className="w-1/6 h-full flex">
            <div className="ml-10 my-auto">
              <BranchSelection
                currentBranch={currentBranch}
                setCurrentBranch={setCurrentBranch}
                branches={branches}
              />
            </div>
          </div>
          <div className="w-4/6 h-full"></div>
          <div className="w-1/6 h-full flex">
            <div className="mx-auto my-auto flex flex-col">
              <div className="mx-auto"></div>
            </div>
          </div>
        </div>
        <div className="my-4 flex flex-col">
          {currentBranch === "常滑" && (
            <div className="my-6  ml-8">
              <kbd className="px-2 py-1.5 text-xl font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">
                1階
              </kbd>
            </div>
          )}

          <Suspense fallback={<div>Loading...</div>}>
            <DeliveryStaffTable // Pass the props with their values
              headers={headers}
              firstHalf={firstHalf}
              secondHalf={secondHalf}
              firstRowSpan={firstRowSpan}
              secondRowSpan={secondRowSpan}
              firstHeading={firstHeading}
              secondHeading={secondHeading}
              firstCell={firstCell}
              secondCell={secondCell}
              updatedCountsData={updatedCountsData}
              FT={FT}
              ST={ST}
              setParentData={setFirstChild}
            />
          </Suspense>
          <div className="flex justify-center py-12 ">
            <button
              onClick={() => handleSubmit(1)}
              className="bg-blue-600 text-white px-4 py-2 rounded"
            >
              {language.submit} {currentBranch === "常滑" && " (1階)"}
            </button>
          </div>
        </div>

        {currentBranch === "常滑" && data2 && (
          <div className="my-4 flex flex-col bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="my-6  ml-8">
              <kbd className="px-2 py-1.5 text-xl font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">
                2階
              </kbd>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <DeliveryStaffTable // Pass the props with their values
                headers={headers}
                firstHalf={firstHalf}
                secondHalf={secondHalf}
                firstRowSpan={firstRowSpan}
                secondRowSpan={secondRowSpan}
                firstHeading={firstHeading}
                secondHeading={secondHeading}
                firstCell={firstCell}
                secondCell={secondCell}
                updatedCountsData={updatedCountsData2}
                FT={FT}
                ST={ST}
                setParentData={setSecondChild}
              />
            </Suspense>
            <div className="flex justify-center py-12 ">
              <button
                onClick={() => handleSubmit(2)}
                className="bg-blue-600 text-white px-4 py-2 rounded"
              >
                {language.submit} {currentBranch === "常滑" && " (2階)"}
              </button>
            </div>
          </div>
        )}
      </div>

      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          message={language.modal.submission_successful}
        />
      )}
    </div>
  );
}

export default DeliveryStaffCheckbox;
